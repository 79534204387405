import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Seo from "../components/seo"
import ServicesHero from "../components/ServicesHero"
import ServiceItem from "../components/ServiceItem"
import GetStarted from "../components/GetStarted"

const NumberItem = ({ title, description }) => (
  <div className="flex flex-col">
    <span className="text-2xl lg:text-4xl text-black font-bold">{title}</span>
    <span className="text-base lg:text-lg text-black">{description}</span>
  </div>
)

const TextItem = ({ title, description }) => (
  <div className="flex flex-col">
    <span className="text-2xl lg:text-4xl text-black mb-1">{title}</span>
    <p className="text-base lg:text-lg text-black" style={{ maxWidth: 530 }}>
      {description}
    </p>
  </div>
)

function ServicesPage() {
  const data = useStaticQuery(graphql`
    query {
      allServicesPageJson {
        edges {
          node {
            id
            title
            description
            iconName
          }
        }
      }
    }
  `)

  return (
    <>
      <Seo
        title="Services"
        description="With more than 10 years of experience, we do our best to provide outstanding custom software development services. By leveraging the latest tech stack and the best development practices, we help you bring your business to a new level. We pay close attention to detail and develop robust, feature-rich and user-oriented solutions."
      />
      <ServicesHero />
      <div className="bg-gray-100 py-5 sm:py-8 lg:py-12">
        <div className="container">
          <p className="text-xl md:text-2xl lg:text-3.5xl font-medium text-black">
            With more than 10 years of experience, we do our best to provide
            outstanding custom software development services. By leveraging the
            latest tech stack and the best development practices, we help you
            bring your business to a new level. We pay close attention to detail
            and develop robust, feature-rich and user-oriented solutions.
          </p>
        </div>
      </div>
      <div className="container bg-white py-16 md:py-24 lg:py-32">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-y-10 lg:gap-y-24 md:gap-x-10 lg:gap-x-24">
          {data.allServicesPageJson.edges.map(({ node: service }) => {
            const { id, title, description, iconName } = service
            return (
              <ServiceItem
                key={id}
                iconName={iconName}
                title={title}
                description={description}
              />
            )
          })}
        </div>
      </div>
      <div className="bg-gray-100 py-6 sm:py-10 lg:py-16">
        <div className="container flex flex-col divide-y divide-gray-300">
          <div className="pb-6 sm:pb-10 lg:pb-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-5 md:gap-x-5">
            <NumberItem title="10+" description="years of experience" />
            <NumberItem title="25+" description="in-house experts" />
            <NumberItem title="150+" description="clients across the globe" />
            <NumberItem
              title="250+"
              description="projects in different business domains"
            />
          </div>
          <div className="pt-6 sm:pt-10 lg:pt-16">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-y-16 md:gap-x-16">
              <TextItem
                title="Agile methodologies"
                description="Our software development process is iterative, collaborative, and responsive, so our team can reduce inefficiencies and build products as fast as possible."
              />
              <TextItem
                title="Commitment to compliance"
                description="Our team strictly adheres to domestic and international legal regulations and ensures that only secure code is used in your software applications."
              />
              <TextItem
                title="Code quality"
                description="Our team performs a code analysis and continuous code quality inspection to reduce the technical debt and mitigate any possible risks."
              />
              <TextItem
                title="Dedicated teams"
                description="Our teams possess a do-or-die mentality and a forward-looking approach to build a solution that will help you thrive into the future."
              />
            </div>
          </div>
        </div>
      </div>
      <GetStarted />
    </>
  )
}

export default ServicesPage
