import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames';

/**
 * 
 * @param {{ to: string, title: string, size: 'default' | 'large', external: boolean }} param0 
 */
function PrimaryLinkButton({ to, title, size = 'default', external }) {

  const className = classnames(
    "inline-block bg-primary-dark text-gray-200 font-bold text-xl leading-8  hover:text-white hover:bg-black focus:bg-black focus:outline-none",
    {
      "px-5 py-2": size === 'default',
      "px-6 py-3": size === 'large',
    }
  );

  if (external) {
    return (
      <a href={to} title={title} className={className}  target="_blank" rel="noopener noreferrer">
        {title}
      </a>
    );
  }


  return (
    <Link to={to} title={title} className={className}>
      {title}
    </Link>
  );
}

export default PrimaryLinkButton
