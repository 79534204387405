import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PrimaryLinkButton from "./PrimaryLinkButton"

function GetStarted(props) {
  const data = useStaticQuery(graphql`
    {
      mobileBackground: file(
        relativePath: { eq: "mobile/get-started-bg.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, layout: CONSTRAINED)
        }
      }
      desktopBackground: file(relativePath: { eq: "get-started-bg.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1000, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <div className="bg-white py-10 lg:pt-16 relative overflow-hidden">
      <div className="absolute bottom-0 right-0 pointer-events-none lg:hidden">
        <GatsbyImage
          image={data.mobileBackground.childImageSharp.gatsbyImageData}
          className="w-56 h-auto"
          alt=""
        />
      </div>
      <div className="container relative z-10 pb-24 lg:pt-10">
        <h3 className="font-bold text-4xl sm:text-5xl leading-snug sm:leading-tight text-gray-800">
          Ready to get started?
        </h3>
        <p className="font-medium md:font-normal text-lg text-gray-800 leading-6 pt-1">
          Request a meeting or talk to our technical sales team to answer your
          questions.
        </p>
        <div className="pt-5">
          <PrimaryLinkButton to="/company" title="get in touch" />
        </div>
      </div>
      <div className="relative z-0 mx-auto w-full max-w-screen-2xl pointer-events-none hidden lg:block">
        <div className="absolute bottom-0 right-0 w-1/2 flex justify-end">
          <GatsbyImage
            image={data.desktopBackground.childImageSharp.gatsbyImageData}
            className="w-full max-w-md h-auto ml-auto"
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

export default GetStarted
