import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

function ServicesHero() {
  const data = useStaticQuery(graphql`
    {
      mobileBackground: file(
        relativePath: { eq: "mobile/company-hero-bg.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      desktopBackground: file(relativePath: { eq: "company-hero-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <div
      className="bg-white relative transition-all duration-75 overflow-hidden"
      style={{ height: 430 }}
    >
      <div className="absolute right-0 bottom-0 top-0 left-0 pointer-events-none flex items-end lg:items-center">
        <div className="w-11/12 max-w-md h-auto lg:hidden ml-auto">
          <GatsbyImage
            image={data.mobileBackground.childImageSharp.gatsbyImageData}
            alt=""
          />
        </div>
        <div className="w-full mx-auto max-w-screen-2xl hidden lg:block">
          <div className="w-1/3 h-auto ml-auto">
            <GatsbyImage
              image={data.desktopBackground.childImageSharp.gatsbyImageData}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="relative h-full flex flex-col">
        <div className="container flex-1 flex flex-col justify-center">
          <h1 className="text-4xl sm:text-4.5xl md:text-6xl leading-tight font-medium text-gray-900">
            Services
          </h1>
          <p className="max-w-4xl text-lg leading-snug md:text-2xl font-medium text-gray-700 pt-2 pb-5">
            We build digital products that enhance people’s lives, help them
            navigate the world, help them communicate with each other, and solve
            their problems faster.
          </p>
        </div>
      </div>
    </div>
  )
}

export default ServicesHero
