import React from "react"

import Outsourcing from "../svg/outsourcing.svg"
import Support  from "../svg/support-management.svg"
import DataSolutions from "../svg/data-solutions.svg"
import DigitalCommerce from "../svg/digital-commerce.svg"
import CloudApplication from "../svg/cloud-applications.svg"
import WebDevelopment from "../svg/web-development.svg"
import MobileDevelopment from "../svg/mobile-development.svg"
import ProductDesign from "../svg/product-design.svg"


const icons = {
  "outsourcing": Outsourcing,
  "support-management": Support,
  "data-solutions": DataSolutions,
  "digital-commerce": DigitalCommerce,
  "cloud-applications": CloudApplication,
  "web-development": WebDevelopment,
  "mobile-development": MobileDevelopment,
  "product-design": ProductDesign,
}

function ServiceItem({ iconName, title, description }) {
  const Icon = icons[iconName] || "div"

  return (
    <div className="flex">
      <div className="flex-shrink-0 mt-1 md:mt-2">
        <Icon className="h-12 w-12 lg:h-20 lg:w-20 fill-current" />
      </div>
      <div className="flex flex-col mx-4 md:mx-5 lg:mr-8">
        <span className="text-xl md:text-2xl lg:text-3.5xl font-medium text-black mb-1">{title}</span>
        <p className="text-black text-sm md:text-base lg:text-lg">{description}</p>
      </div>
    </div>
  )
}

export default ServiceItem